.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
  }




.bg-home {
    background: url('./Poles.jpg');
    margin-top: 25vh;
    width: 100%;
    height: 80vh;
    background-size: cover;
    background-position:center;
    background-repeat: no-repeat;
    
}

.first-tile {
    display: flex;
    height: 100%;
    width: 80%;
}

.home-content-first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    line-height: 2.4em;
}

.sub-content-first {
    display: flex;
    color: #fbfbfb;
}

.hr-line {
        border: none;
        border-top: 2px solid #ffffff;
        margin: 18px 10px;
        width: 1vw;
}

.title-head {
    font-weight: 900;
    animation: slide 1.5s forwards;
}

@keyframes slide {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: translateX(0%);
    }
  }
  

.sub-title-head {
    font-weight: 200;
    animation: slide2 1.5s forwards;
}

@keyframes slide2 {
    from {
      transform: translateX(50%);
    }
    to {
      transform: translateX(0%);
    }
  }

.topic-head {
    display: flex;
}

.hr-line-tiles {
    border: none;
    border-top: 3px solid #E4CE33;
    margin: 18px 10px;
    width: 2vw;
}

.aboutUs {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-heading {
    margin-top: 2em;
}

.about-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4em;
}

.background-content {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: center;
    align-items: center;
    line-height: 1.4em;
}

.background-content > h5 {
    margin-top: 1em;
    font-weight: 200;
}



@media screen and (max-width: 600px) {

  
    .bg-home {
      margin-top: 10vh;
      height: 60vh;
      background-size: cover;
    }
  
    /* .first-tile, .home-content-first, .sub-content-first {
      flex-direction: column;
      width: 100%;
    } */
    

    .first-tile {
        margin-left: 0.5em;
        font-size: 0.9rem;
    }
  
     .sub-title-head {
      font-size: 0.7rem; 
      margin-left: 0.4em;
    }
  
    .topic-head, .aboutUs, .about-tile, .background-content {
      align-items: center;
      padding: 0 5%; /* Add some padding */
    }
  
    .background-content {
      width: 100%; /* Full width on mobile */
    }

    .background-content > h5 {
      font-size: 1.05rem;
    }
  
    .hr-line, .hr-line-tiles {
      width: 5vw; /* Adjust horizontal line width */
    }
  
    /* Adjust animations for mobile or disable them as needed */
    @keyframes slide {
        from {
          transform: translateX(-20%);
        }
        to {
          transform: translateX(0%);
        }
    }
  
    @keyframes slide2 {
        from {
          transform: translateX(20%);
        }
        to {
          transform: translateX(0%);
        }
    }
  }