/* * {
    font-family: 'Poppins', sans-serif;
}

.head-timing {
    display: flex;
    flex-direction: row;
    background-color: #0D2D3E;
    
    padding: 1em;
    justify-content: space-between;
   
}

.head-lists {
    background-color: white;
}

.head-time{
    display: flex;
    justify-content: center;
}

.head-timing-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: white;
    width: 85%;
}

.hidden {
    visibility: hidden;
}

.left-space {
    margin-left: 2vw;
}

.head-lists{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.head-lists-items {
    display: flex;
    list-style: none;
}

.list-item {
    margin-left: 2vw;
}

.list-item:hover {
    cursor: pointer;
    text-decoration: underline;
}


.logo:hover {
    cursor: pointer;
}

.link-react {
    text-decoration: none;
    color: black;
}

.link-react:hover {
    text-decoration: underline;
}


.active-link {
    text-decoration: underline;
}


.hamburger {
    display: none;
    cursor: pointer;
  }



@media screen and (max-width: 600px) {

    .logo{
        margin: 0.3em 0;
        width: 150px;
    }



    .head-timing{
        padding: 0;
    }


    .head-timing {
        height: 4vh;
        font-size: 0.8rem;
    }

    .head-time {
        margin-top: 0.6em;
    }

    .logo-container {
        display: flex;
        width: 100%;
        justify-content: space-around;
    }

    .head-timing-content, .head-lists {
        flex-direction: row;
        width: 100%;
    }


    .list-item {
        margin: 0.5em 0.5em; 
    }

    .hamburger {
        display: block;
        color: rgb(58, 57, 57);
        font-size: 1.5em;
        margin-left: auto;
        padding: 1em;
      }
    
      .head-lists-items {
        position: absolute;
        top: 60px; 
        right: 0;
        width: 100%;
        background-color: white;
        display: none;
        flex-direction: column;
        align-items: center;
        padding: 1em 0;
        transition: max-height 0.3s ease-in-out;
      }
    
      
      .head-lists-items.open {
        display: flex;
      }
    
      .list-item {
        margin: 1em 0; 
      }
} */

* {
    font-family: 'Poppins', sans-serif;
}

/* Desktop-specific styles for better header presentation */

/* General styling for the header */
.head {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Adjusted header timing bar styling for desktops */
.head-timing {
  justify-content: center;
  padding: 1em;
  background-color: #0D2D3E;
}

.head-timing-content {
  width: 90%;
  max-width: 1200px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 1rem;
}

.left-space {
  margin-left: 2vw;
}

/* Desktop navigation bar styling */
.head-lists {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.logo-container {
  max-width: 1200px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Remove hamburger on desktop */
.hamburger {
  display: none;
}

/* List items display for desktop */
.head-lists-items {
  display: flex;
  gap: 2em;
  list-style: none;
  align-items: center;
  margin: 0;
  padding: 0;
  transition: none;
  list-style-type: none;
}


.list-item {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}


.link-react {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.link-react:hover {
  color: #007bff;
}

/* Remove the overlay on desktop as it is not required */
.overlay {
  display: none;
}

/* Logo adjustments for desktop */
.logo {
  width: 200px;
}

/* Hover underline effect for list items */
.link-react::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #007bff;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.link-react:hover::after {
  transform: scaleX(1);
}

/* Add better spacing between header sections on desktop */
.head-time {
  display: flex;
  align-items: center;
  gap: 1em;
}

/* Timing div spacing for better desktop presentation */
.timing-div {
  display: flex;
  gap: 1.5em;
  font-size: 0.9rem;
}


.head-timing {
    display: flex;
    flex-direction: row;
    background-color: #0D2D3E;
    padding: 0.5em;
    justify-content: space-between;
   
}

.hidden {
    visibility: hidden;
}

.left-space {
    margin-left: 2vw;
}


.head-time{
    display: flex;
    justify-content: center;
}

.head-timing-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: white;
    width: 85%;
}
/* Basic styles */
.head-lists {
    background-color: white;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1em;
}

.hamburger {
    display: none;
    cursor: pointer;
}

/* Overlay for the open menu */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1000;
    pointer-events: none;
}

.overlay.open {
    opacity: 1;
    pointer-events: all;
}

/* Full-screen menu on mobile */
@media screen and (max-width: 600px) {
  .logo {
    width: 150px;
  }

  .hamburger {
    display: block;
    color: #333;
    font-size: 1.5em;
  }

  .timing-div {
    display: flex;
    justify-content: space-around;
  }



  /* .closed {
    margin-left: 2em;
  } */

  .head-timing-content {
    font-size: 0.8rem;
    width: 100%;
  }

  .head-lists-items {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 75%;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2em 1.5em;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1100;
    list-style-type: none;
  }

  .head-lists-items.open {
    transform: translateX(0);
  }

  /* List items styling */
  .list-item {
    margin: 1em 0;
    font-size: 1.2em;
    color: #333;
    font-weight: 500;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;
  }

  /* Staggered animation for menu items */
  .head-lists-items.open .list-item:nth-child(1) { animation-delay: 0.1s; }
  .head-lists-items.open .list-item:nth-child(2) { animation-delay: 0.2s; }
  .head-lists-items.open .list-item:nth-child(3) { animation-delay: 0.3s; }
  .head-lists-items.open .list-item:nth-child(4) { animation-delay: 0.4s; }

  /* Fade-in animation */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateX(10px); }
    to { opacity: 1; transform: translateX(0); }
  }

  /* Link Styling */
  .link-react {
    text-decoration: none;
    color: #333;
    position: relative;
    transition: color 0.2s ease;
  }

  /* Underline on hover */
  .link-react:hover {
    color: #007bff;
  }

  /* Underline transition */
  .link-react::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #007bff;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  .link-react:hover::after {
    transform: scaleX(1);
  }
}
