
   .container-poDetails {
    display: flex;
    flex-direction: row; /* Default layout for desktop */
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
  
  .po-details,
  .upload-files {
    flex: 1;
    min-width: 300px; /* Prevent shrinking below this width */
  }

  .show-expense-button:hover {
    background-color: #f40862;
    cursor: pointer;
  }


  .po-expenses-title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1.3em;
    color: #333;
  }

  .button-expense-list {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  /* Hover effect for buttons */
  .button-expense-list:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  /* Focus effect for accessibility */
  .button-expense-list:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.8);
  }
  
  /* Active state for buttons */
  .button-expense-list:active {
    background-color: #003f7f;
    transform: scale(0.95);
  }
  
  /* Add custom styling for button alignment (if needed) */
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }



  .expense-container-mobile {
    width: 50%;
  }

  .piechart-mobile {
    width: 30%;
  }
  
  
  @media (max-width: 768px) {
    
    .container-poDetails {
      flex-direction: column; /* Stack cards vertically on smaller screens */
    }
  
    .po-details,
    .upload-files {
      width: 100%;
      font-size: 0.9rem; /* Full width for each card on mobile */
    }

    .pie-container-mobile {
        flex-direction: column;

    }

    .button-expense-list {
        font-size: 14px;
        padding: 8px 16px;
        margin: 8px;
      }


    .piechart-mobile,
    .expense-container-mobile {
        width: 100%;
    }
  }
  