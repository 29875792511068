.labour-expenses-container {
    margin: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  

  .generate-pdf-btn {
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .generate-pdf-btn:hover {
    background-color: #45a049;
  }
  

  .labour-expenses-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .labour-expenses-table th,
  .labour-expenses-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .labour-expenses-table th {
    background-color: #007bff;
    color: white;
  }
  
  .labour-expenses-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .no-data {
    text-align: center;
    font-style: italic;
    color: #666;
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 8px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #74a7c9;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .pagination button:hover:not(.active) {
    background-color: #43a3cf;
  }
  
  /* Total amount container */
  .total-amount-container {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .labour-expenses-table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  
    .labour-expenses-table th,
    .labour-expenses-table td {
      padding: 8px;
      font-size: 0.9rem;
    }
  
    .pagination button {
      padding: 6px 10px;
      font-size: 0.85rem;
    }
  
    .total-amount-container {
      font-size: 1rem;
    }
  }
  