.employees-container {
    padding: 50px;
    text-align: center;
  }
  
  .employees-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .employees-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  /* Actual employee card styles */
  .employee-card {
    width: 200px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .employee-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .employee-name {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .employee-designation {
    font-size: 1rem;
    color: #666;
  }
  
  /* Skeleton loading styles */
  .skeleton-card {
    width: 200px;
    padding: 20px;
    background-color: #e0e0e0;
    border-radius: 8px;
    text-align: center;
    animation: pulse 1.5s ease-in-out infinite;
  }
  
  .skeleton-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 auto 15px;
  }
  
  .skeleton-text {
    height: 15px;
    margin: 10px 0;
    background-color: #ccc;
    border-radius: 4px;
  }
  
  .skeleton-name {
    width: 60%;
    margin: 0 auto 10px;
  }
  
  .skeleton-designation {
    width: 80%;
    margin: 0 auto;
  }
  
  /* Pulsing animation for skeleton loading effect */
  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }



  @media screen and (max-width: 768px) {
  .employee-card {
    width: 90%;
  }
  }




  