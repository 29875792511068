/* AssignWork.css */

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f0f2f5;
    margin: 0;
    padding: 0;
    color: #333;
  }
  
  .assign-work-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }

  .employee-header-name {
    margin-top: 1em;
    margin-bottom: -1em;
  }
  
  h2 {
    text-align: center;
    font-size: 32px;
    color: #444;
    margin-bottom: 30px;
    font-weight: 500;
  }
  
  .assign-form {
    margin-bottom: 40px;
    background-color: #f9f9f9;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  }
  
  .form-group {
    margin-bottom: 20px;
    position: relative; /* Positioning for dropdown styling */
  }
  
  .form-group label {
    display: block;
    font-weight: 600;
    color: #555;
    margin-bottom: 10px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 12px 15px;
    font-size: 16px;
    color: #333;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
  }
  
  .form-group input:focus,
  .form-group select:focus {
    outline: none;
    border-color: #007BFF;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  }
  
  .suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background-color: #dad7d7;
    border: 1px solid #ddd;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    z-index: 10;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .suggestions-list li {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
  
  .suggestions-list li:hover {
    background-color: #afaeae;
    font-weight: 800;
  }
  
  /* Button Styling */
  .assign-button {
    width: 100%;
    padding: 14px;
    font-size: 18px;
    background-color: #007BFF;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 15px;
  }
  
  .assign-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .assigned-work-list {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  }
  
  .assigned-work-list h3 {
    font-size: 28px;
    color: #444;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .assigned-work-list ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .assigned-work-list li {
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 15px;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1 1 calc(33.333% - 20px); /* Three cards per row */
    min-width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .assigned-work-list li.completed {
    background-color: #d1ffd6; /* Light green for completed work */
  }
  
  .assigned-work-list li:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .assigned-work-list strong {
    color: #007BFF;
  }
  
  .remove-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #FF5252;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .remove-button:hover {
    background-color: #d43f3f;
    transform: translateY(-2px);
  }
  
  .finished-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .finished-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }
  
  .badge {
    display: inline-block;
    background-color: #28a745;
    color: #fff;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 12px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  