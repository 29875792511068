.bill-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2em;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #4a90e2;
    margin-bottom: 1.5em;
  }
  
  .search-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5em;
    justify-content: space-between;
    align-items: flex-end; 
  }
  
  .filter-item {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
  }
  
  .filter-item label {
    margin-bottom: 0.5em;
    font-weight: bold;
  }
  
  .filter-item input {
    padding: 0.7em;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1rem;
    background-color: #b8d9ff;
    color: rgb(72, 72, 72);
    font-weight: 600;
  }


.delete-button {
    padding: 0.6em 1.2em;
    display: flex;
    background-color: #e74c3c;  
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: 0.5em; 
  }
 
  .delete-button:hover {
    background-color: #c0392b;  
    transform: translateY(-3px); 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);  
  }
  

  .delete-button:active {
    background-color: #a5281a;  
    transform: translateY(0);  
    box-shadow: none; 
  }
  
  

  .button-container {
    display: flex;
    gap: 1em;
  }
  
  .search-button, .reset-button {
    padding: 0.8em 1.5em;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #4a90e2;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #357abd;
  }
  
  .reset-button {
    background-color: #e74c3c;
  }
  
  .reset-button:hover {
    background-color: #c0392b;
  }
  
  .invoice-results {
    margin-top: 2em;
  }
  
  .invoice-results h3 {
    color: #333;
    margin-bottom: 1em;
  }
  
  .invoice-results ul {
    list-style-type: none;
    padding: 0;
  }
  
  .invoice-results li {
    background-color: #f9f9f9;
    margin-bottom: 1em;
    padding: 1em;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  }
  
  .invoice-results li strong {
    display: inline-block;
    width: 120px;
  }
  

  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5em;
  }
  
  .pagination-controls button {
    padding: 0.5em 1em;
    margin: 0 0.5em;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-controls span {
    margin: 0 1em;
    font-weight: bold;
  }
  