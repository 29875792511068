
   * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Poppins', sans-serif;
    background-color: #f0f2f5;
    color: #333;
  }
  
  header {
    text-align: center;
    margin: 1.5em 0 2.5em 0;
  }
  
  header h3 {
    color: #4a90e2;
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .bill-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 1.5em;
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    margin-bottom: 2em;
  }
  
  .bill-info-bar {
    margin-right: 1.5em;
    margin-bottom: 1em;
  }
  
  .bill-info-bar > label {
    margin-right: 0.5em;
    font-weight: 600;
    color: #333;
  }
  
  .bill-info-bar > input {
    padding: 0.7em 1em;
    border-radius: 8px;
    border: 1px solid #ddd;
    transition: border 0.3s ease;
    font-size: 1rem;
    width: 250px;
  }
  
  .bill-info-bar > input:focus {
    border-color: #4a90e2;
    outline: none;
  }
  
  .bill-radio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .bill-radio label {
    font-weight: 600;
    color: #555;
  }
  
  .bill-details {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5em;
  }
  
  .bill-box {
    background-color: #fff;
    color: #333;
    width: 90%;
    height: 60vh;
    overflow-y: auto;
    border-radius: 12px;
    padding: 1.5em;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  }
  
  .bill-label {
    display: flex;
    justify-content: space-between;
    padding: 0.8em 0;
    background-color: #4a90e2;
    border-bottom: 2px solid #f0f2f5;
    border-radius: 5px;
  }
  
  .bill-label > h3 {
    font-size: 1.1rem;
    font-weight: 700;
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
  
  .invoice-detail {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
  }
  
  .invoice {
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
    border-bottom: 1px solid #f0f2f5;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .invoice:hover {
    background-color: #afc2d6;
    transform: translateY(-3px);
    cursor: pointer;
    color: #000000;
  }

  /* For Webkit browsers (Chrome, Safari, Edge) */
.bill-box::-webkit-scrollbar {
  width: 10px;  /* Width of the scrollbar */
}

.bill-box::-webkit-scrollbar-track {
  background-color: #f1f1f1;  /* Background color of the scrollbar track */
  border-radius: 10px;  /* Rounded corners for the track */
}

.bill-box::-webkit-scrollbar-thumb {
  background-color: #888;  /* Color of the scrollbar thumb */
  border-radius: 10px;  /* Rounded corners for the thumb */
  border: 2px solid #f1f1f1;  /* Adds space around the thumb */
}

.bill-box::-webkit-scrollbar-thumb:hover {
  background-color: #555;  /* Darker color when hovering over the scrollbar thumb */
}

/* For Firefox */
.bill-box {
  scrollbar-width: thin;  /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1;  /* Thumb color and track color */
}

  
  .invoice > h3 {
    font-size: 1rem;
    color: #555;
    padding: 0.2rem;
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .invoice > h3:not(:last-child) {
    border-right: 1px solid #949191; 
  }
  
  .button {
    padding: 0.8em 2em;
    background-color: #4a90e2;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: white;
    font-weight: 600;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .button:hover {
    background-color: #357abd;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .logout-button {
    margin-top: 1.2em;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .button-log {
    background-color: #e74c3c;
    padding: 0.6em 1.5em;
    color: white;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .button-log:hover {
    background-color: #c0392b;
    cursor: pointer;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  @media screen and (max-width: 768px) {
    .bill-box {
      width: 98%;
    }
  
    .bill-info {
      width: 98%;
      flex-direction: column;
    }
  
    .bill-info-bar {
      margin-right: 0;
      margin-bottom: 1em;
      width: 100%;
    }
  
    .bill-label > h3,
    .invoice > h3 {
      font-size: 1rem;
    }
  
    .button {
      margin-top: 1em;
      width: 100%;
      padding: 0.8em;
    }
  }



