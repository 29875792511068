.low-stock-todo-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .back-icon {
    color: #6a6e71;
    margin-left: 0;
  }

  .back-icon:hover {
    cursor: pointer;
    color: #2d93e1;
  }
  
  .header-title {
    flex-grow: 1;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .add-item-button, .send-email-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 15px;
  }

  .send-email-button {
    background-color: #55bd10;
  }
  
  .add-item-button:hover, .send-email-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }

  .send-email-button:hover {
    background-color: #59d109;
  }
  
  .low-stock-list {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .low-stock-list li {
    padding: 8px;
    margin-top: 8px;
    background-color: #e9ecef;
    border-radius: 4px;
    font-size: 16px;
  }
  