/* Styling the container */
.filter-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #f2efef;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
  }
  
  /* Styling labels */
  .filter-label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  /* Styling select dropdowns */
  .filter-select {
    padding: 0.5rem 1rem;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    outline: none;
    transition: all 0.3s ease;
  }


  
  .button-all-expenses {
    padding: 10px 20px;
    margin: 10px;
    background-color: #32a852;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  /* Hover effect for buttons */
  .button-all-expenses:hover {
    background-color: #27b74d;
    transform: scale(1.05);
  }
  
  .filter-select:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }
  
  /* Styling date inputs */
  .filter-date-input {
    padding: 0.5rem 1rem;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: antiquewhiteW;
    color: #333;
    outline: none;
    transition: all 0.3s ease;
  }
  
  .filter-date-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }
  
  /* Group styles for date/month selectors */
  .filter-date-group {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .filter-input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .profit-expense-div {
    width: 40%;
  }

  .profit-pie-div {
    width: 30%;
    margin-left: 1em;
  }

  .profit-management-div {
    display: flex;
    justify-content: center;
  }
  
  /* Add responsiveness */
  @media (max-width: 768px) {
    .filter-date-group {
      flex-direction: column;
    }

    .profit-management-div {
        flex-direction: column;
        width: 95%;
    }

    .profit-expense-div {
        width: 100%;
    }

    .profit-pie-div {
        width: 100%;
        margin-left: 0;
    }
  }
  