
.contacts {
    display: flex;
    background-color: #24496D;
    color: white;
    margin-top: 5em;
    padding: 3em;
    justify-content: space-around;
}


.message-submit {
  background-color: rgb(223, 154, 25);
  padding: 0.6em;
  border-radius: 4px;
  border: 1px solid rgb(172, 112, 0);

}

.message-submit:hover {
  cursor: pointer;
  background-color: rgb(173, 114, 3);
}


.contact-div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}


.mail-link {
    text-decoration: none;
    color: white;
}

.message-div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.subject-input {
    margin-bottom: 1em;
    width: 96.5%;
}

.textarea-input {
    width: 350px;
    height: 150px;
    border: 1px solid #ccc; 
  border-radius: 5px; 
  padding: 10px; /* Add padding inside the textarea */
  color: #333; /* Change the text color */
  background-color: #f8f8f8;
  resize: none; 
}

input {
    border:none;
    border-bottom: 1px solid white;
    background-color:#24496D;
    color:white;
}

input:focus {
    outline: none;
  }

  input::placeholder {
    color: #ffffff;
  }

  .nameInput {
    margin-right: 1.5em;
    margin-bottom: 1em;
  }

  .contact-text {
    font-weight: 200;
    font-size: 1rem;
  }

  .messageLabel {
    margin-bottom: 0.5em;
  }


  @media screen and (max-width: 600px) {
    .contacts {
        flex-direction: column;
        padding: 1em;
        margin-top: 2em;
    }

    .contact-div, .message-div {
        width: 100%;
    }
    .emailInput {
      width: 100%;
      margin-bottom: 1em;
    }

    .contact-text {
      font-size: 1.05rem;
    }

    .title-contact {
      text-align: center;
      color: #ccc;
    }

    .leave-message {
      text-align: center;
      margin: 1.5em  0 0 0;
      font-size: 1.3rem;
    }

    .subject-input, .textarea-input, .nameInput {
        width: 100%; /* Full width */
        margin-right: 0; /* Remove margin-right for .nameInput */
    }

    .textarea-input {
        height: auto; /* Adjust height for better usability */
    }
}