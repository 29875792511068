.loading-circle {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #007bff; /* Adjust color as needed */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: auto;
  }



  .loading-circle2 {
    position: fixed; /* Use fixed positioning */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust positioning for true centering */
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #007bff; /* Adjust color as needed */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  .loading-background {
    position: fixed; /* Cover the entire viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Black background with opacity */
    z-index: 999; /* Ensure it's above other content */
  }
  
  /* Ensure the spin animation is defined */
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }


  
  .progress-bar {
    height: 10px; /* Adjust height as needed */
    background-color: #ddd;
    border-radius: 5px; /* Add border radius for rounded corners */
    overflow: hidden; /* Clip overflowing progress element */
  }
  
  .progress {
    height: 100%; /* Fill entire progress bar height */
    background-color: #007bff; /* Progress bar color */
    transition: width 0.5s ease-in-out; /* Add smooth transition */
  }
  

  