.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
}



.serviceBG {
    margin-top: 20vh;
    background: url('./serviceBG.png');
    width: 100%;
    height: 40vh;
    background-size: cover;
    background-position:center;
    background-repeat: no-repeat;
    opacity: 1;
    color: rgb(0, 0, 0);
    font-weight: 900;
    font-size: 1.1rem;

    text-transform: uppercase;
    display: flex;
    justify-content: center;
   
}

.service-offer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.serviceoffer-heading {
    display: flex;
    margin-top: 5em;
    width: 75%;
}

.title-service {
    font-size: 1.9rem;
}

.hr-line-service {
    border: none;
    border-top: 3px solid #24496D;
    margin: 20px 10px;
    width: 2vw;
}

.shopIcon {
    width: 100%
}

.service-tiles-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.service-tiles {
    display: flex;
    overflow: hidden;
}

.service-tile {
    width: 20vw;
    flex: 1 1 calc(33.33% - 2em); 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
   
    background-image: linear-gradient(to right,  #F7F7F7 40%,  #24496D 60%);
    padding: 1.3em;
    word-wrap: wrap;
    margin-right: 3em;
    margin-top: 4em;
    border-radius: 4px;

    background-size: 300% 100%;
    transition: background-position 1s;
    background-position: 0;
}

.service-tile:hover {
    cursor: pointer;
    color: white;
    background-position: 100%;
}


.service-tile-heading {
    font-weight: 900;
    font-size: 1.2rem;
    margin: 0.5em 0.5em;
    text-align: center;
}

.service-tile-body {
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 1.4rem;
    text-align: center;
}



@media screen and (max-width: 600px) {
  

    .serviceoffer-heading {
        margin-top: 3em;
        width: 90%; 
    }

    .title-service {
        font-size: 1.5rem; 
    }

    .hr-line-service {
        width: 5vw; 
    }

    .service-tiles-outer, .service-tiles {
        flex-direction: column; 
    }

    .service-tile {
        width: 80vw; 
        margin-right: 0; 
        margin-bottom: 2em; 
    }

    .service-tile-heading, .service-tile-body {
        font-size: 1rem; 
    }
}