.pie-expense-container {
    /* max-width: 800px; */
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }

  .pie-diagram {
    margin-bottom: 2em;
  }



  .pie-expenses-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    color: #333;
  }