body {
    font-family: 'Poppins', sans-serif;
    background-color: #f4f7f9;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
  }
  
  .system-button {
    margin: 1.5em 0;
    background-color: #6a1b9a;
    padding: 1.2em 2em;
    border-radius: 10px;
    color: white;
    text-align: center;
    width: 80%;
    max-width: 400px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .system-button:hover {
    background-color: #8e44ad;
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .system-button h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .nav-link:hover {
    cursor: pointer;
    color: #2d2929;
  }

  .navbar-color {
    background-color: #4a90e2;
  }
  
  .dashboard-header {
    text-align: center;
    margin-top: 1.5em;
    color: #4a90e2;
    
  }
  
  @media screen and (max-width: 768px) {
    .system-button {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .system-button {
      width: 95%;
      padding: 1em;
    }
  
    .system-button h3 {
      font-size: 1.2rem;
    }
  }
  
