/* POExpense.css */

.po-expense-container {
    /* max-width: 800px; */
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .po-expense-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .expense-section {
    margin-bottom: 30px;
  }
  
  .section-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #007bff;
  }
  
  .form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .form-input {
    flex: 1;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .form-input:focus {
    border-color: #007bff;
  }
  
  .read-only {
    background-color: #f1f1f1;
    cursor: not-allowed;
  }
  
  .add-btn {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-btn:hover {
    background-color: #0056b3;
  }
  
  .expense-list-section {
    margin-top: 20px;
  }
  
  .expense-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .expense-item {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: #333;
  }
  
  .expense-item:nth-child(even) {
    background-color: #f1f1f1;
  }
  