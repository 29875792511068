.loading-circle {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #007bff; /* Adjust color as needed */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: auto;
  }

  .head-logo {
    background-color: linear-gradient(to right, #7b4397, #dc2430);
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  