.settings-icon:hover {
    cursor: pointer;
    background-color: rgb(21, 97, 183);
}

.settings-icon {
    background-color: rgb(27, 58, 118);
    border-radius: 30px;
    padding: 0.4em;
}

.hover-edit:hover {
    cursor: pointer;
    color: rgb(15, 116, 183);
}

.hover-success:hover {
    cursor: pointer;
    color: rgb(14, 175, 14);
}


.loading-circle {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #007bff; /* Adjust color as needed */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: auto;
  }