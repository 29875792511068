.projects-container {
    padding: 50px;
    text-align: center;
    background-color: #f4f4f9;
  }
  
  .projects-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .project-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .project-slide-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .project-info {
    text-align: center;
  }
  
  .project-slide-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .project-slide-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .project-link {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .project-link:hover {
    background-color: #0056b3;
  }
  