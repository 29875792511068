.inventory-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .search-bar {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    background-color: #ffe1e1;
    color: #333;
    border-radius: 4px;
    font-size: 16px;

  }

  .order-button {
    margin-bottom: 1em;
    background-color: #007bff;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .order-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .order-button:active {
    background-color: #003d80;
    transform: translateY(0);
  }
  

  input::placeholder {
    font-weight: bold;
    opacity: 0.5;
    color: rgb(0, 0, 0);
  }
  
  .inventory-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .inventory-table th,
  .inventory-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .inventory-table th {
    background-color: #f2f2f2;
  }
  
  .price-input {
    width: 80px;
    padding: 5px;
    border: 1px solid #ddd;
    background-color: rgb(230, 116, 104);
    border-radius: 4px;
    text-align: right;
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .pagination button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .pagination button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .pagination span {
    font-size: 16px;
    color: #333;
  }
  